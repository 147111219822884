<template>
    <v-dialog
        v-model="dialog"
        scrollable
        max-width="440px"
        persistent
        >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
            x-large
            color="success"
            class="mintBtn"
            width="100%"
            onclick="gtag('event', 'click',{'event_category': 'Renew my policy', 'event_label': 'Header'});"
            v-bind="attrs"
            v-on="on"
            @click="showError = false;">
                Renew my policy
            </v-btn>
        </template>
        <template v-if="showForm">
            <v-card id="step_one">
                <v-btn
                    icon
                    class="closeDialog"
                    @click="
                        clearFormClose();
                        dialog = false;
                    "
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <h4 class="steps">Step 1</h4>
                <v-card-title>Please enter your details</v-card-title>
                <v-card-text>
                    <v-alert
                        type="error"
                        class="mint_alert"
                        icon="fas fa-exclamation-triangle"
                        v-if="showError"
                        id="errorAlert"
                        prominent
                    >
                        <span v-if="!errorMessageStatusCode" v-html="errorMessage" id="errorText"></span>
                    </v-alert>
                    <v-form
                        ref="form"
                        v-model="renewForm"
                        lazy-validation
                    >
                        <v-select
                            v-model="insurance_type"
                            :items="insuranceType"
                            label="Insurance type"
                            required
                            filled
                            class="rounded-0"
                            :rules="itRules"
                            @click="clearForm(); showError = false;"
                        ></v-select>
                        <v-text-field
                            v-if="insurance_type == 'Home Insurance' || insurance_type == 'Landlords Insurance' || insurance_type == null || insurance_type == ''"
                            v-model="policynumber"
                            id="policyNumber"
                            required 
                            label="Policy number"
                            filled
                            class="rounded-0"
                            maxlength=12
                            :rules="policynumberRules"
                            validate-on-blur
                        ></v-text-field>
                        <v-text-field
                            v-if="insurance_type === 'Rent Protection' || insurance_type === 'Tenants Contents'"
                            v-model="shortpolicynumber"
                            id="policyNumber"
                            required 
                            label="Policy number"
                            filled
                            class="rounded-0"
                            maxlength=10
                            :rules="shortpolicynumberRules"
                            validate-on-blur
                        ></v-text-field>
                        <v-text-field
                            v-if="insurance_type === 'Tenants Liability'"
                            v-model="tlipolicynumber"
                            id="policyNumber"
                            required 
                            label="Policy number"
                            filled
                            class="rounded-0"
                            maxlength=11
                            :rules="tlipolicynumberRules"
                            validate-on-blur
                        ></v-text-field>
                        <v-text-field
                            v-if="insurance_type != 'Rent Protection'"
                            v-model="surname" 
                            required 
                            id="surname"
                            label="Surname"
                            filled
                            class="rounded-0"
                            :rules="surnameRules"
                            validate-on-blur
                        ></v-text-field>
                        <v-text-field
                            v-if="insurance_type === 'Rent Protection'"
                            v-model="surnameCompany" 
                            required 
                            id="surname"
                            label="Surname/Company name"
                            filled
                            class="rounded-0"
                            :rules="surnameRules"
                            validate-on-blur
                        ></v-text-field>
                        <v-text-field
                            label="Postcode"
                            filled
                            class="rounded-0"
                            v-model="postcode" 
                            id="postcode" 
                            title="UK Postcode" 
                            maxlength="8" 
                            required
                            :rules="postcodeRules"
                            persistent-hint
                            hint="Please enter the postcode of your insured address"
                            validate-on-blur
                        ></v-text-field>
                        <v-btn
                            :disabled="!renewForm"
                            x-large
                            color="success"
                            class="nordicBtn"
                            width="100%"
                            @click="
                                validate();
                            "
                            >
                            Find my policy
                        </v-btn>
                    </v-form>
                </v-card-text>
            </v-card>
        </template>
        <template v-if="showLoader">
            <v-card id="loader">
                <v-card-text class="loader">
                    <pulse-loader></pulse-loader>
                    <p>We're just looking for your policy details.</p>
                    <p>This'll only take a moment!</p>
                </v-card-text>
            </v-card>
        </template>
         <template v-if="renewalResults">
            <v-card id="step_two">
                <v-btn
                    icon
                    class="closeDialog"
                    @click="
                        dialog = false;
                        clearForm();
                    "
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <h4 class="steps">Step 2</h4>
                <v-card-title>Please check your details</v-card-title>
                <v-card-text>
                    <v-simple-table
                    class="renewalTable">
                        <template v-slot:default>
                            <tbody>
                            <tr>
                                <td>Policy Number</td>
                                <td>{{ info.PolicyReference }}</td>
                              </tr>
                              <tr>
                                <td>Policy Type</td>
                                <td>{{ insurance_type }}</td>
                              </tr>
                              <tr>
                                <td>Surname</td>
                                <td>{{ (surname || surnameCompany) }}</td>
                              </tr>
                              <tr>
                                <td>Renewal date</td>
                                <td>{{ info.RenewalDate | formatDate}}</td>
                              </tr>
                              <tr>
                                <td>Renewal Premium</td>
                                <td>&pound;{{ info.AmountDue.toFixed(2) }}</td>
                              </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                    <v-btn
                        x-large
                        color="success"
                        class="mintBtn mt-5" 
                        width="100%"
                        onclick="gtag('event', 'click',{'event_category': 'Renew my policy', 'event_label': 'Proceed to payment'});"
                        :href="PaymentUrl">
                            Proceed to payment
                    </v-btn>
                    <v-btn
                        outlined
                        class="borderBtn rounded-0 mt-5" 
                        align="center"
                        onclick="gtag('event', 'click',{'event_category': 'Renew my policy', 'event_label': 'Back to step 1'});"
                        @click="showLoader = false; showForm = true; renewalResults = false;">
                            Back to step 1
                    </v-btn>
                </v-card-text>
            </v-card>
        </template>
    </v-dialog>
</template>

<script>
import axios from 'axios';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
  export default {
    components: {
        PulseLoader
    },
    data () {
      return {
        lazy: false,
        dialog: false,
        renewForm: false,
        insurance_type: '',
        itRules: [
            v => !!v || 'Insurance type is required'
        ],
        policynumber: '',
        policynumberRules: [
            v => !!v || 'Policy number is required',
            v => (v && v.length == 12) || 'Policy number must be 12 characters long',
            v => /[bpnlBPNLLPlp]{4}[0-9]{8}/.test(v) || 'Please enter a valid policy number'
        ],
        shortpolicynumber: '',
        shortpolicynumberRules: [
            v => !!v || 'Policy number is required',
            v => (v && v.length == 10) || 'Policy number must be 10 characters long',
            v => /[rpRPtcTC]{2}[0-9]{8}/.test(v) || 'Please enter a valid policy number'
        ],
        tlipolicynumber: '',
        tlipolicynumberRules: [
            v => !!v || 'Policy number is required',
            v => (v && v.length == 11) || 'Policy number must be 11 characters long',
            v => /[tliTLI]{3}[0-9]{8}/.test(v) || 'Please enter a valid policy number'
        ],
        surname: '',
        surnameRules: [
            v => !!v || 'Surname is required',
            v => (v && v.length >= 2) || 'Surname must be more than 2 characters',
            v => /^[a-zA-Z\s-']*$/.test(v) || 'Please enter only alpha characters, spaces, hyphens, and apostrophes in this field'
        ],
        surnameCompany: '',
        postcode: '',
        postcodeRules: [
            v => !!v || 'Postcode is required',
            v => /^(?![Ii][Mm])(?![Jj][Ee])(?![Gg][Yy])[A-Za-z]{1,2}[0-9A-Za-z]{1,2} ?[0-9][A-Za-z]{2}$/.test(v) || 'Please enter a valid UK Postcode'
        ],
        insuranceType: [
            'Home Insurance',
            'Landlords Insurance',
            'Rent Protection',
            'Tenants Contents',
            'Tenants Liability'
        ],
        showLoader: false,
        showForm: true,
        renewalResults: false,
        info: null,
        AmountDue: '',
        policyType: '',
        RenewalDate: '',
        errorMessage: '',
        errorMessageStatusCode: '',
        PaymentUrl: '',
        showError: false,
        statusCode: ''
      }
    },
    mounted() {
        if(sessionStorage.shortpolicynumber) this.shortpolicynumber = sessionStorage.shortpolicynumber;
        if(sessionStorage.tlipolicynumber) this.tlipolicynumber = sessionStorage.tlipolicynumber;
        if(sessionStorage.policynumber) this.policynumber = sessionStorage.policynumber;
        if(sessionStorage.postcode) this.postcode = sessionStorage.postcode;
        if(sessionStorage.surname) this.surname = sessionStorage.surname;
        if(sessionStorage.surnameCompany) this.surnameCompany = sessionStorage.surnameCompany;
        if(sessionStorage.insurance_type) this.insurance_type = sessionStorage.insurance_type;
        if(sessionStorage.AmountDue) this.AmountDue = sessionStorage.AmountDue;
        if(sessionStorage.RenewalDate) this.RenewalDate = sessionStorage.RenewalDate;
    },
    watch:{
        policynumber(newName) {
            sessionStorage.policynumber = newName;
        },
        shortpolicynumber(newName) {
            sessionStorage.shortpolicynumber = newName;
        },
        tlipolicynumber(newName) {
            sessionStorage.tlipolicynumber = newName;
        },
        surname(newName) {
            sessionStorage.surname = newName;
        },
        surnameCompany(newName) {
            sessionStorage.surnameCompany = newName;
        },
        insurance_type(newName) {
            sessionStorage.insurance_type = newName;
        },
        postcode(newName) {
            sessionStorage.postcode = newName;
        },
        AmountDue(newName) {
            sessionStorage.AmountDue = newName;
        },
        RenewalDate(newName) {
            sessionStorage.RenewalDate = newName;
        },
        renewalResults: function (val) {
          this.sessionTimeout();
        },
        dialog(visible) {
            if (visible) {
                this.clearForm();
            }
        }
    },
    methods: {
        validate () {
            if (this.$refs.form.validate()) {
                this.showLoader = true; 
                this.showForm = false;
                this.showError = false;
                this.findPolicy();
            }
            
        },
        formatPrice(value) {
            let val = (value/1).toFixed(2).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
        clearForm () {
            this.insurance_type = "";
            this.policynumber = "";
            this.shortpolicynumber = "";
            this.tlipolicynumber = "";
            this.surname = "";
            this.surnameCompany = "";
            this.postcode = "";
            sessionStorage.clear();
        },
        clearFormClose () {
            this.insurance_type = "";
            this.policynumber = "";
            this.shortpolicynumber = "";
            this.tlipolicynumber = "";
            this.surname = "";
            this.surnameCompany = "";
            this.postcode = "";
            sessionStorage.clear();
            this.$refs.form.resetValidation();
        },
        clearSession () {
            sessionStorage.clear();
        },
        gaTrack () {
            gtag('event', 'click',{'event_category': 'Renew my policy', 'event_label': 'Header'});
        },
        sessionTimeout () {
            setTimeout(() => {
                this.renewalResults = false;
                this.showLoader = false;
                this.showError = true;
                this.showForm = true;
                this.errorMessage = 'Sorry, your session has timed out, please check your details and try again.'
            }, 120000)
        },
        modalLookup() {
            setTimeout(() => {
                this.renewalResults = true;
                this.showLoader = false;
            }, 2000);
        },
        findPolicy() {
            axios.get((this.policynumber || this.shortpolicynumber || this.tlipolicynumber)+'/Renewal?name='+(this.surname || this.surnameCompany)+'&postcode='+this.postcode)
                .then(response => (
                    this.info = response.data,
                    //console.log (response.data),
                    this.policyType = response.data.PolicyType,
                    this.AmountDue = response.data.AmountDue,
                    this.RenewalDate = response.data.RenewalDate,
                    this.PaymentUrl = response.data.PaymentUrl,
                    this.statusCode = response.data.StatusCode,
                    setTimeout(() => {
                        this.renewalResults = true;
                        this.showLoader = false;
                    }, 2000)
                ))
                .catch(error => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    //console.log(error.response.data);
                    //console.log(error.response.status);
                    setTimeout(() => {
                        this.showLoader = false;
                        this.showError = true;
                        this.showForm = true;
                        this.errorMessage = error.response.data.Messages[0] 
                        this.errorMessageStatusCode = error.response.data.Messages[0].Summary  
                    }, 2000)
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                // console.log(error.request);
                    setTimeout(() => {
                        this.showLoader = false;
                        this.showError = true;
                        this.showForm = true;
                        this.errorMessage = "We're very sorry but our card payment service is currently unavailable. Please try again later or call us on 0345 601 1050."
                    }, 2000) 
                } else {
                    // Something happened in setting up the request that triggered an Error
                    //console.log('Error', error.message);
                }
            })
        }
    }
  }
</script>